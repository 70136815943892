<template>
  <div id="Login" class="login fullheight" style="background-color: #DDD" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="'text-align: center; padding-top: 100px; padding-bottom: '+(height - 700)+'px;'">
          <div class="login__container" style="height: 600px !important; margin: auto;">
            <div class="login__inner" style="text-align: left;">
              <div class="login__image">
                <img src="logo.png" style="width: 200px; margin: 0 50px;" alt="Logo Oscuro">
              </div>
              <div class="login__header pt-5" style="padding-top: 100px;">
                  <h4>Inicia Sesión</h4>
              </div>
              <br />
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                  </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template>
        <CToast v-for="(toast, key) in toasts" :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
      return {
          height: 400,
          email: '',
          password: '',
          remind: false,
          image: 'img/sigepa.webp',
          welcome: '',
          cta: ''
      }
  },
  components: {
    Loading
  },
  mounted: async function() {
    this.loading();
    let response = await ws.getParameters(); 

    this.height = window.innerHeight;

    if(response.type == "success"){
      let parameters = response.data;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = param1.value;

      let param2 = this.$findElementByName(parameters, "LOGIN_WELCOME");
      this.welcome = param2.value;

      let param3 = this.$findElementByName(parameters, "LOGIN_CTA");
      this.cta = param3.value;
    }
    this.loaded();
  },
  methods: {
    login: async function(){
      this.loading();
      let params = {
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("domain", data.account.domain);
        localStorage.setItem("token", data.api_token);

        axios.defaults.headers.common['domain'] = data.account.domain;
        axios.defaults.headers.common['api-token'] = data.api_token;

        this.loaded();
        window.location.href = "/#/home"; return;
      }
      else{
        this.showToast("error",response.message);
        this.loaded();
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
    toasts () {
      return store.state.toasts;
    },
  },  
}
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
